import api from './index'
import { getCookie, setCookie, removeCookie } from '../helpers/browser';
import { localStorageClear } from '../helpers/utils';

const MAX_DAYS_SANITIZATION = 20;

export const requestCode = (email, pn) => {
  return api.Post("/auth/request-code", {
    email: email,
    personalNumber: pn
  })
}

export const requestCodeByType = (type, data) => {
  return api.Post("/auth/request-code", {
    [type]: data[type],
    method: data.method
  })
}

export const loginWithCode = (key, code) => {
  return api.Post("/auth/login/code", { Key: key, Code: code })
}

export const loginWithPassword = (email, password) => {
  var json = JSON.stringify({Email: email, Password: password})
  return api.Post("/auth/login/password", json)
  .then(json => {
    setAuth(json.token);
    setCurrentUser(json.user);
  });
}

export const loginWithGAuth = (email, gtoken, telephonenumber = "", personalnumber = "") => {
  return api.Post("/auth/login/gauth", { email, gtoken, telephonenumber, personalnumber })
}

export const loginWithMSAuth = (email, gtoken, telephonenumber = "", personalnumber = "") => {
  return api.Post("/auth/login/msauth", { email, gtoken, telephonenumber, personalnumber })
}


export const loginWithPreAuth = (key) => {
  return api.Post("/auth/login/preauth", { key })
}

export const validateSession = () => {
  return api.Post("/auth/validate")
    .then(user => {
      return user
    })
}

export const setAuth = (token, user) => {
  setCookie("token", token, 7);
  if (user !== undefined) {
    setCurrentUser(user)
  }
}

/*
    Gets the current authentication token from cookie
*/
export const getAuth = () => {
  var token = getCookie("token");
  // Every time we fetch auth, we set the cookie again, thus making the
  // session last longer
  if (token && token.length > 0) {
    setAuth(token);
  } else {
    token = null;
  }
  return token;
}

export const localStoragePrefixes = {
  "LIA_LOCALSTORAGE_PREFIX": "lia",
  "PAGE_TIMESTAMP_PREFIX": "fz_pages_timestamps"
};

export const localStoragExceptionExactMatchListForDelete = [
  localStoragePrefixes.LIA_LOCALSTORAGE_PREFIX + ".*",
  localStoragePrefixes.PAGE_TIMESTAMP_PREFIX
];

export const clearLocalStorageWithCare = () => {
  const localStorageKeys = Object.keys(localStorage);

  if (!localStoragExceptionExactMatchListForDelete.length) {
    localStorageClear();
  }

  const exactPatterns = localStoragExceptionExactMatchListForDelete.map((item) => {
    return "^" + item + "$";
  });
  
  const listPattern = new RegExp(exactPatterns.join("|"));

  for (const key of localStorageKeys) {
      const test = listPattern.test(key);
      if (!test) {
        localStorage.removeItem(key);
      }
  }
}

export const userShouldBeSanitized = () => {
  var token = getCookie("token");
  if (!token && localStorage.currentUser) {
    const oneDay = 24 * 60 * 60 * 1000;
    const lastActivity = new Date(JSON.parse(localStorage.currentUser).lastActivity);
    const today = new Date();
    const diffDays = Math.round(Math.abs((today - lastActivity) / oneDay));
    if (diffDays && diffDays > MAX_DAYS_SANITIZATION) {
      return true;
    }
  }
  return false;
}

export const clearAuth = () => {
  localStorage.removeItem('currentUser');
  let cookies = getCookie()

  Object.keys(cookies).forEach((cookie) => {
    if(cookie !== "disclaimer") {
      removeCookie(cookie)
    }
  })

  return new Promise((resolve) => resolve(true))
};

export const getCurrentUser = () => {
  if (userShouldBeSanitized()) {
    localStorageClear();
  }
  var userAsString = localStorage.getItem("currentUser");
  if (userAsString === null) {
    return null;
  }

  try {
    return JSON.parse(userAsString);
  } catch (ex) {
    return null;
  }
}

const setCurrentUser = (user) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
}
