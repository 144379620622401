import { getCookie } from '../helpers/browser';
import ClientDomains from './domains';
import { pickBy } from 'lodash-es';

class FzClientApi {
  
  requestsMap = {};
  
  constructor(baseTarget = "api/v2/", domain = "hub", port = "4000", devMode = false) {
    window.finanzero = window.finanzero ? window.finanzero : {};
    // Could use webpack proxy but this need our local go server to understand /api/* urls
    var isBeta = /((beta)|(^home\.)|(192.168.))/i.test(window.location.hostname)
    var isDev = devMode || /(local)/i.test(window.location.hostname)
    this.baseUrl = isDev ? (`http://localhost:${baseTarget.indexOf("dory") > -1 ? 3027 : port}/`) :
      isBeta ? "https://" + domain + "-beta.finanzero.com.br/" :
        "https://" + domain + ".finanzero.com.br/";

    // sobrescrevendo baseUrl para api-homol caso um cookie fz-homol-mode estiver presente e com valor "true"
    var isHomol = getCookie("fz-homol-mode") === "true";
    this.baseUrl = isHomol && domain == "api" ? "https://api-homol.finanzero.com.br/" : this.baseUrl;

    const queryString = 'overrideapi';
    if (window.ReactNativeWebview || window.location.search.indexOf(queryString) > -1) {
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get(queryString)) {
        localStorage.setItem(queryString, urlParams.get(queryString));
      } else {
        localStorage.removeItem(queryString);
      }
    }

    if (localStorage.getItem(queryString)) {
      this.baseUrl = `http://${localStorage.getItem(queryString)}/`;
    }

    window.finanzero.isTest = isDev || isBeta
    this.baseStaticFilesUrl = isDev || isBeta ? "https://beta.finanzero.com.br" : "https://finanzero.com.br";
    // this.baseUrl = "https://api-beta.finanzero.com.br/";
    // this.baseUrl = "https://api.finanzero.com.br/";
    this.baseUrl = isDev && baseTarget == "api/v2/" ? this.baseUrl : this.baseUrl + baseTarget;
    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function (searchString, position) {
        position = position || 0;
        return this.substring(position, searchString.length) === searchString;
      };
    }

    this.DefaultPage = 20;
    this.DefaultAppStack = 200;
    if (baseTarget.startsWith("api/")) {
      this.domains = new ClientDomains(this);
    }
    this.avoidMultipleCalls = this.avoidMultipleCalls.bind(this)
    this.Post = this.Post.bind(this)
    this._post = this._post.bind(this)
  }

  getHeaders() {
    return pickBy({
      'Content-Type': 'application/json; charset=UTF-8',
      'Authorization': getCookie("token") || getCookie("fz-cof-token"),
      'fz-gauth-authorization': getCookie("fz-ga-token"),
      'fz-channel': getCookie("fz-channel"),
      'fz-event': getCookie("fz-event"),
      'fz-notificationid': getCookie("fz-notificationid"),
      'fz-iteration': getCookie("fz-iteration")
    })
  }

  _buildQueryString(filter) {
    var queryFilter = (query, value) => {
      if (value && value.toISOString) {
        return '&' + query + '=' + value.toISOString();
      } else {
        return ((value !== undefined && value !== null && value.length > 0) ? ('&' + query + '=' + encodeURIComponent(value)) : '')
      }
    }
    var queryString = ""
    for (var f in filter) {
      if (filter.hasOwnProperty(f)) {
        queryString += queryFilter(f, filter[f])
      }
    }
    return (queryString);
  }


  _post(serviceUrl, data, callback, errorCallback) {
    var header = new Headers(this.getHeaders());
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_post() ", err);
      }
    }

    var init = {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      if (response.status === 200 || response.status === 205) {
        response.json().then(function (json) {
          if (callback) {
            callback({ ...json, status: response.status });
          }
        }).catch((e) => {
          console.warn("ERROR", e)
        });
      } else if (response.status > 200 && response.status < 300) {
        if (callback) {
          callback(null);
        }
      } else if (response.status >= 400) {
        if (response.status === 409) {
          window.location.reload(true)
        }
        response.json()
          .then((err) => errorCallback({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            step: "EXECUTE",
            method: "POST",
            err
          }))
          .catch((err) => errorCallback({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            step: "EXECUTE",
            method: "POST",
            err
          }));
      }
    }).catch(function (error) {
      errorCallback({ ok: false, status: 500, statusText: error, step: "FETCH", method: "POST" });
    });

  }

  Post(serviceUrl, data) {
    return this.avoidMultipleCalls(serviceUrl, data, "POST", this._post)
  }

  avoidMultipleCalls(
    serviceUrl = "/domains",
    data = {},
    method = "GET",
    methodFunc = this._get
  ) {
    const requestKey = `${method}:${serviceUrl}`;
    if (serviceUrl === "/send-event" || !this.requestsMap.hasOwnProperty(requestKey)) {
      this.requestsMap[requestKey] = new Promise((fulfill, reject) => {
        return methodFunc(
          serviceUrl,
          data,
          (json) => fulfill(json),
          (err) => reject(err)
        );
      })
        .then((ok) => ok)
        .catch((err) => {
          console.error(err);
          throw err;
        })
        .finally(() => {
          delete this.requestsMap[requestKey];
        });
    } else {
      console.warn("Returning existing promise for the same request", {
        serviceUrl,
        method,
      });
    }
    return this.requestsMap[requestKey];
  }

  _delete(serviceUrl, callback, errorCallback) {
    var header = new Headers(this.getHeaders());
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_delete() ", err);
      }
    }
    var init = {
      method: 'DELETE',
      headers: header,
      mode: 'cors',
      cache: 'default'
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      if (response.status < 400 && contentType && contentType.indexOf("application/json") !== -1) {
        if (response.status !== 204) {
          response.json().then(function (json) {
            callback(json);
          });
        } else {
          // 204
          errorCallback({
            ok: true,
            status: 204,
            err: "dont exists",
            statusText: "dont exists",
            step: "EXECUTE",
            method: "GET"
          });
        }
      } else {
        if (response.status >= 400) {
          response.json().then((err) => errorCallback({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            step: "EXECUTE",
            method: "GET",
            err
          }))
            .catch((err) => errorCallback({
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              step: "EXECUTE",
              method: "GET",
              err
            })
            );
        } else {
          errorCallback({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            step: "EXECUTE",
            method: "GET"
          })
        }
      }
    }).catch(function (error) {
      errorCallback({ ok: false, status: 500, statusText: error, step: "FETCH", method: "GET", err: error });
    });
  }

  _get(serviceUrl, callback, errorCallback) {
    var header = new Headers(this.getHeaders());
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_get() ", err);
      }
    }
    var init = {
      method: 'GET',
      headers: header,
      mode: 'cors',
      cache: 'default'
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      var contentType = response.headers.get("content-type");
      if (response.status < 400 && contentType && contentType.indexOf("application/json") !== -1) {
        if (response.status !== 204) {
          response.json().then(function (json) {
            callback(json);
          });
        } else {
          // 204
          errorCallback({
            ok: true,
            status: 204,
            err: "dont exists",
            statusText: "dont exists",
            step: "EXECUTE",
            method: "GET"
          });
        }
      } else {
        if (response.status >= 400) {
          response.json().then((err) => errorCallback({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            step: "EXECUTE",
            method: "GET",
            err
          }))
            .catch((err) => errorCallback({
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              step: "EXECUTE",
              method: "GET",
              err
            })
            );
        } else {
          errorCallback({
            ok: response.ok,
            status: response.status,
            statusText: response.statusText,
            step: "EXECUTE",
            method: "GET"
          })
        }
      }
    }).catch(function (error) {
      errorCallback({ ok: false, status: 500, statusText: error, step: "FETCH", method: "GET", err: error });
    });
  }

  Get(serviceUrl) {
    return new Promise((fulfill, reject) => {
      this._get(serviceUrl, (json) => fulfill(json), (err) => reject(err))
    })
  }

  Delete(serviceUrl) {
    return new Promise((fulfill, reject) => {
      this._delete(serviceUrl, (json) => fulfill(json), (err) => reject(err))
    })
  }

  _put(serviceUrl, data, callback, errorCallback) {
    var header = new Headers(this.getHeaders());
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_put() ", err);
      }
    }

    var init = {
      method: 'PUT',
      headers: header,
      body: JSON.stringify(data)
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(function (json) {
          callback(json);
        });
      } else if (response.status >= 400) {
        response.json().then((err) => {
          if (errorCallback !== undefined) {
            errorCallback({
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              step: "EXECUTE",
              method: "PUT",
              err
            })
          }
        }).catch((err) => {
          if (errorCallback !== undefined) {
            errorCallback({
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              step: "EXECUTE",
              method: "PUT",
              err
            })
          }
        })
      }

    }).catch(function (error) {
      errorCallback({ ok: false, status: 500, statusText: error, step: "FETCH", method: "PUT" });
      //app.ApiError();
    });

  }

  Put(serviceUrl, data) {
    return new Promise((fulfill, reject) => {
      this._put(serviceUrl, data, (json) => fulfill(json), (err) => reject(err))
    })
  }

  _patch(serviceUrl, data, callback, errorCallback) {
    var header = new Headers(this.getHeaders());
    if (errorCallback === undefined) {
      errorCallback = (err) => {
        console.warn("_patch() ", err);
      }
    }

    var init = {
      method: 'PATCH',
      headers: header,
      body: JSON.stringify(data)
    };
    var req = new Request(this._apiUrl(serviceUrl));
    fetch(req, init).then(function (response) {
      if (response.status === 200) {
        response.json().then(function (json) {
          callback(json);
        });
      } else if (response.status >= 400) {
        response.json().then((err) => {
          if (errorCallback !== undefined) {
            errorCallback({
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              step: "EXECUTE",
              method: "PATCH",
              err
            })
          }
        }).catch((err) => {
          if (errorCallback !== undefined) {
            errorCallback({
              ok: response.ok,
              status: response.status,
              statusText: response.statusText,
              step: "EXECUTE",
              method: "PATCH",
              err
            })
          }
        })
      }

    }).catch(function (error) {
      errorCallback({ ok: false, status: 500, statusText: error, step: "FETCH", method: "PATCH" });
      //app.ApiError();
    });

  }

  Patch(serviceUrl, data) {
    return new Promise((fulfill, reject) => {
      this._patch(serviceUrl, data, (json) => fulfill(json), (err) => reject(err))
    })
  }

  BuildImgUrl(image) {
    return this.baseStaticFilesUrl + image
  }

  _apiUrl(serviceUrl) {
    if (serviceUrl[0] === "/") {
      return this.baseUrl + serviceUrl.slice(1);
    }
    return this.baseUrl + serviceUrl;
  }
}

var currentApi
var msgApi
var directApi
var bureauApi
var doryApi

currentApi = !currentApi ? new FzClientApi("api/v2/", "api") : currentApi
msgApi = !msgApi ? new FzClientApi("messages/v1/") : msgApi;
directApi = !directApi ? new FzClientApi("", "api") : directApi;
bureauApi = !bureauApi ? new FzClientApi("bureau/v1/") : bureauApi;
doryApi = !doryApi ? new FzClientApi("dory/v1/") : doryApi;

export default currentApi
export { msgApi, FzClientApi, directApi, bureauApi, doryApi }
